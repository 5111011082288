import React from 'react';
import Link from 'next/link';
import classNames from 'classnames';

const NotFound = () => {
  return (
    <div
      className={classNames(
        'h-screen',
        'w-screen',
        'flex',
        'flex-col',
        'items-center'
      )}
    >
      <div
        className={classNames(
          'flex-1',
          'flex',
          'flex-col',
          'items-center',
          'justify-center'
        )}
      >
        <h1 className={classNames('text-3xl', 'mb-4')}>
          Could not find the page
        </h1>
        <p>
          Go{' '}
          <button
            className={classNames('underline', 'hover:no-underline')}
            onClick={() => {
              window.history.back();
            }}
          >
            back
          </button>
          , or to{' '}
          <Link href="/">
            <a className={classNames('underline', 'hover:no-underline')}>
              the front page
            </a>
          </Link>
        </p>
      </div>
    </div>
  );
};

export default NotFound;
